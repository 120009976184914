$btn-border-radius: 2.5rem;
$btn-border-radius-lg: 2.5rem;
$font-family-sans-serif: Montserrat, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$gray-100: #dfe1df;
$gray-800: #303c42;
$input-border-radius: 30px;
$input-padding-x: 22px;
$input-padding-y: 22px;
$input-line-height: 1.15;
$gray-600: #00b0b3;
$orange: #fdb71a;
$yellow: #fdb71a;
$success: #00b0b3;
$success-text: #003644;
$blue: #00b0b3;
$primary: #003644;
$info: #00b0b3;
$secondary: #00b0b3;
$form-check-input-width: 2em;
$red: #fdb71a;


@import "bootstrap/scss/bootstrap";

input::placeholder {
    color: #00b0b3 !important;
}

label {
    font-weight: 300;
}

.content-container {
    min-height: 630px;
}

.form-check-label,
.form-check {
    font-weight: 300 !important;
    color: rgb(48, 60, 66);
}

.menu {
    background-color: white !important;
    color: black;
}

.bg-dark {
    /*background-color: #002e6d !important;*/
    background-color: white !important;

}

.btn-primary {
    color: #fff;
}

.btn-lg {
    text-transform: uppercase;
}

// body {
//     background-color: #ececec;
// }

h4 {
    text-transform: uppercase;
}

a,
a:focus,
button,
button:focus {
    outline: none;
}

.clicker {
    cursor: pointer;
}


.forbidden {
    opacity: 0.5;
    background-color: dimgrey;
    color: linen;
    cursor: not-allowed;
}

.full-square {
    border: 0;
    /*width: 100%;
    border-radius: 0rem !important;*/
}

.accordion-button {
    width: 100%;
    font-size: 1rem !important;
    align-items: center;
    border: 0;
    border-radius: 0;
    display: flex;
    overflow-anchor: none;
    position: relative;
    text-align: left;
}

.brokerVideo {
    width: 100%;
}

.form-label {
    font-weight: 600;
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
}

/* .videoWrapper iframe {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
} */


.desktop-menu {
    padding-top: 0.36rem !important;
    padding-bottom: 1.1rem !important;
    font-size: 1rem !important;
    /*box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.05), 0 1px 2px -1px rgb(0 0 0 / 0.1);*/
}

.phone-button {
    margin-top: 1rem !important;
    margin-right: 3rem !important;
}

.broker-logo {
    /*
    position: fixed;
    top: 10px;
    left: 80px;*/
    /*width: 300px;*/
    height: 53px;
    margin-left: 19px;
    margin-top: 2px;
    padding-left: 5px;
    /*height: 4.12rem;*/
}



.broker-logo-mobile {
    height: 2rem;
    margin-top: 1rem;
}

.plan-picker {
    background-color: #C2E6E5 !important;
    color: #003644 !important;
}

.footer-area {
    background-color: #003644 !important;
    color: white;
    margin-bottom: -20px;
}

.footer-secure {
    /*color: rgb(40, 167, 69);*/
    color: #C2E6E5 !important;
}

.footer-area a:link {
    color: white;
    text-decoration: none;
}

.footer-area a:hover {
    color: rgb(199, 201, 212);
    text-decoration: none;
}

.custom-progress {
    border-radius: 3rem !important;
}